import { useCallback, useContext, useEffect, useState } from 'react';
import styles from './TimeModal.module.scss';
import { TimeModalContext } from '../../../contexts/TimeModalContext';
import { UserContext } from '../../../contexts/UserContext';
import Button from '../../button/Button';
import InputField from '../../inputfield/InputField';
import TIME_LOG_ENTRY_TYPES from '../../../constants/timeLogEntryTypes';
import { motion } from 'framer-motion';
import { Timestamp, doc, setDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { currentTimeLogSetting } from '../../../utils/currentTimeLogSetting';
import DANISH_HOLIDAYS from '../../../constants/danishHolidays';
import { isDateLocked } from '../../../utils/isDateLocked';
import { hoursBetweenTwoDates } from '../../../utils/hoursBetweenTwoDates';
import { TimeLogWarning } from '../../../types/User';
import { getCompanyStartDate } from '../../../utils/getCompanyStartDate';

function TimeModal() {
  const { company, user, userInfo } = useContext(UserContext);
  const { timeLog, preValues, isOpen, close } = useContext(TimeModalContext);
  const [showFromToDates, setShowFromToDates] = useState(false);
  const [showTimeOffType, setShowTimeOffType] = useState(false);
  const [showDateRangeToggle, setShowDateRangeToggle] = useState(false);
  const [showIncludeBreakToggle, setShowIncludeBreakToggle] = useState(true);
  const [includeBreak, setIncludeBreak] = useState(true);
  const [daysCalculated, setDaysCalculated] = useState(1);
  const [values, setValues] = useState(preValues);
  const [feedback, setFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Gem');

  const closeModal = useCallback(() => {
    const timeLogSetting = currentTimeLogSetting(company, userInfo, new Date());

    // Reset the form
    setValues({
      id: '',
      note: '',
      endTime: Timestamp.now(),
      startTime: Timestamp.now(),
      type: timeLogSetting?.defaultType,
      timeOffType: '',
      hours: 0,
      breakWasIncluded: true,
    });

    // Reset the days calculated
    setDaysCalculated(1);

    // Reset the showFromToDates
    setShowFromToDates(false);

    // Reset the showDateRangeToggle
    setShowDateRangeToggle(false);

    // Reset the showIncludeBreakToggle
    setShowIncludeBreakToggle(true);

    // Reset calculateBreak
    setIncludeBreak(true);

    // Reset the showTimeOffType
    setShowTimeOffType(false);

    // Reset feedback
    setFeedback('');

    // Set submitting to false to enable the submit button
    setSubmitting(false);
    setSubmitLabel('Gem');

    // Close the modal after saving
    close();
  }, [close, userInfo, company]);

  const handleTypeChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    // Reset feedback
    setFeedback('');

    // Check if we need to display additional fields
    if (value === 'holiday' || value === 'timeOff') {
      setShowDateRangeToggle(true);
    } else {
      setShowDateRangeToggle(false);
      setShowFromToDates(false);
    }

    if (value === 'timeOff') {
      setShowTimeOffType(true);
    } else {
      setShowTimeOffType(false);
    }

    // If the type is holiday, sickLeave or timeOff, set starting time to start of normal working hours, and set end time to end of normal working hours
    if (value === 'sickLeave' || value === 'holiday' || value === 'timeOff') {
      const timeLogSetting = currentTimeLogSetting(
        company,
        userInfo,
        values.startTime.toDate()
      );

      setShowIncludeBreakToggle(true);

      const startTime = values.startTime.toDate();
      startTime.setHours(
        timeLogSetting?.from.toDate().getHours() || 0,
        timeLogSetting?.from.toDate().getMinutes() || 0
      );

      const endTime = new Date(startTime);
      endTime.setHours(
        timeLogSetting?.end.toDate().getHours() || 0,
        timeLogSetting?.end.toDate().getMinutes() || 0
      );

      // Since we are setting the start and end time to the same day, we need to calculate the hours
      // We also take into account if the user has a break, and its paid, and subtract that from the hours
      const hours = timeLogSetting?.paidBreak
        ? (endTime.getTime() - startTime.getTime()) / 36e5
        : (endTime.getTime() - startTime.getTime()) / 36e5 -
          (timeLogSetting?.breakDuration || 0) / 60;

      setValues({
        ...values,
        [name]: value,
        startTime: Timestamp.fromDate(startTime),
        endTime: Timestamp.fromDate(endTime),
        hours,
      });
    }
    // If the type is overtime, set starting time to end of normal working hours, and set end time to 1 hour later
    else if (value === 'overtime' || value === 'work') {
      const timeLogSetting = currentTimeLogSetting(
        company,
        userInfo,
        values.startTime.toDate()
      );
      const startTime = values.startTime.toDate();
      startTime.setHours(
        timeLogSetting?.end.toDate().getHours() || 0,
        timeLogSetting?.end.toDate().getMinutes() || 0
      );

      const endTime = new Date(startTime);
      endTime.setHours(startTime.getHours() + 1);

      setValues({
        ...values,
        [name]: value,
        startTime: Timestamp.fromDate(startTime),
        endTime: Timestamp.fromDate(endTime),
        hours: 1,
      });

      setShowIncludeBreakToggle(false);
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    // Reset feedback
    setFeedback('');

    if (name === 'endDate' || name === 'startDate') {
      // If the new date is a locked date, set feedback and return
      if (
        isDateLocked(
          new Date(value),
          userInfo.timeLogApprovedWeeks,
          userInfo.startDate,
          getCompanyStartDate(company)
        )
      ) {
        setFeedback('Du kan sætte datoen til en godkendt og låst dag.');
        return;
      }

      if (name === 'endDate') {
        setValues({
          ...values,
          endTime: Timestamp.fromDate(new Date(value)),
          hours: 0,
        });

        const start = new Date(values.startTime.toDate()).setHours(0, 0, 0, 0);
        const end = new Date(value).setHours(23, 59, 0, 0);

        // Calculate the initial days difference including the start date
        let totalDays = Math.max(1, Math.round((end - start) / 864e5));

        // Check if there are any holidays between the two dates
        const holidays = DANISH_HOLIDAYS.filter(
          (holiday) =>
            new Date(holiday.value).getTime() >= start &&
            new Date(holiday.value).getTime() <= end
        );

        // Subtract holidays from total days
        let nonWorkingDays = holidays.length;

        // Check for notWorking days in the range and subtract them
        for (let i = 0; i < totalDays; i++) {
          const currentDate = new Date(start);
          currentDate.setDate(currentDate.getDate() + i);

          const dayOfWeek = currentDate.getDay(); // Sunday - Saturday: 0 - 6
          const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Assuming weekend is Saturday and Sunday

          const timeLogSetting = currentTimeLogSetting(
            company,
            userInfo,
            currentDate
          );

          if (isWeekend || timeLogSetting?.notWorking) {
            nonWorkingDays++;
          }
        }

        let calculatedDays = totalDays - nonWorkingDays;

        setDaysCalculated(calculatedDays);
      } else {
        setValues({
          ...values,
          startTime: Timestamp.fromDate(new Date(value)),
          hours: 0,
        });

        const start = new Date(value).setHours(0, 0, 0, 0);
        const end = new Date(values.endTime.toDate()).setHours(23, 59, 0, 0);

        // Calculate the initial days difference including the start date
        let totalDays = Math.max(1, Math.round((end - start) / 864e5));

        // Check if there are any holidays between the two dates
        const holidays = DANISH_HOLIDAYS.filter(
          (holiday) =>
            new Date(holiday.value).getTime() >= start &&
            new Date(holiday.value).getTime() <= end
        );

        // Subtract holidays from total days
        let nonWorkingDays = holidays.length;

        // Check for notWorking days in the range and subtract them
        for (let i = 0; i < totalDays; i++) {
          const currentDate = new Date(start);
          currentDate.setDate(currentDate.getDate() + i);

          const dayOfWeek = currentDate.getDay(); // Sunday - Saturday: 0 - 6
          const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Assuming weekend is Saturday and Sunday

          const timeLogSetting = currentTimeLogSetting(
            company,
            userInfo,
            currentDate
          );

          if (isWeekend || timeLogSetting?.notWorking) {
            nonWorkingDays++;
          }
        }

        let calculatedDays = totalDays - nonWorkingDays;

        setDaysCalculated(calculatedDays);
      }
    } else if (name === 'endTime' || name === 'startTime') {
      const [hours, minutes] = value.split(':');
      // Save as Firebase Timestamp
      const date = new Date(values[name].toDate());
      date.setHours(Number(hours));
      date.setMinutes(Number(minutes));

      // Move to nearest 15 minutes, e.g. 12:07 -> 12:15 or 12:23 -> 12:30
      const minutesRounded = Math.ceil(date.getMinutes() / 15) * 15;
      date.setMinutes(minutesRounded);

      const timeLogSetting = currentTimeLogSetting(
        company,
        userInfo,
        new Date(values.startTime.toDate())
      );

      // Calculate hours
      if (name === 'endTime' && values.startTime) {
        const start = values.startTime.toMillis();
        const end = date.getTime();
        const calculatedHours =
          includeBreak && values.type !== 'overtime'
            ? (end - start) / 36e5 - (timeLogSetting?.breakDuration || 0) / 60
            : (end - start) / 36e5;

        setValues({
          ...values,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else if (name === 'startTime' && values.endTime) {
        const start = date.getTime();
        const end = values.endTime.toMillis();
        const calculatedHours =
          includeBreak && values.type !== 'overtime'
            ? (end - start) / 36e5 - (timeLogSetting?.breakDuration || 0) / 60
            : (end - start) / 36e5;
        setValues({
          ...values,
          [name]: Timestamp.fromDate(date),
          hours: calculatedHours,
        });
      } else {
        setValues({ ...values, [name]: Timestamp.fromDate(date) });
      }
    } else {
      // Save as string
      setValues({ ...values, [name]: value });
    }
  };

  const handleSubmit = useCallback(async () => {
    // Set submitting to true to disable the submit button
    setSubmitting(true);

    setSubmitLabel('Gemmer...');

    // If to and from dates are set, we need to create multiple entries across multiple days
    if (showFromToDates) {
      // Start date
      const start = new Date(values.startTime.toDate());
      const startUtc = new Date(
        Date.UTC(
          start.getFullYear(),
          start.getMonth(),
          start.getDate(),
          0,
          0,
          0,
          0
        )
      );

      // End date
      const end = new Date(values.endTime.toDate());
      const endUtc = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 0, 0, 0, 0)
      );

      // Calculate days to nearest whole day so we can create multiple entries
      let totalDays = Math.ceil(
        (endUtc.getTime() - startUtc.getTime()) / 864e5
      );

      // Adjust for the inclusive range by adding 1
      totalDays += 1;

      try {
        for (let i = 0; i < totalDays; i++) {
          const newDate = new Date(startUtc);
          newDate.setUTCDate(startUtc.getUTCDate() + i);

          const dayOfWeek = newDate.getUTCDay(); // Sunday - Saturday: 0 - 6
          const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Assuming weekend is Saturday and Sunday

          const timeLogSetting = currentTimeLogSetting(
            company,
            userInfo,
            newDate
          );

          // Check if the day is a non-working day or a holiday
          const isHoliday = DANISH_HOLIDAYS.some((holiday) => {
            const holidayDate = new Date(holiday.value);
            const holidayUtc = new Date(
              Date.UTC(
                holidayDate.getFullYear(),
                holidayDate.getMonth(),
                holidayDate.getDate()
              )
            );
            return holidayUtc.getTime() === newDate.getTime();
          });

          if (isWeekend || isHoliday || timeLogSetting?.notWorking) {
            continue; // Skip this day
          }

          const fromDefaultTime = timeLogSetting?.from.toDate() || new Date();
          const endDefaultTime = timeLogSetting?.end.toDate() || new Date();

          // Set time of dates based on timeLogSetting -- usually 08:00 - 16:00
          newDate.setHours(
            fromDefaultTime?.getHours() || 0,
            fromDefaultTime?.getMinutes() || 0,
            0
          );

          const newEndDate = new Date(newDate);
          newEndDate.setHours(
            endDefaultTime?.getHours() || 0,
            endDefaultTime?.getMinutes() || 0,
            0
          );

          const newEntry = {
            ...values,
            id: crypto.randomUUID(),
            startTime: Timestamp.fromDate(newDate),
            endTime: Timestamp.fromDate(newEndDate),

            // Calculate hours. If the timeLogSetting has paidBreak set to false, subtract breakDuration from hours.
            // breakDuration is in minutes, so we divide by 36e5 to get hours
            hours: timeLogSetting?.paidBreak
              ? (newEndDate.getTime() - newDate.getTime()) / 36e5
              : includeBreak && values.type !== 'overtime'
              ? (newEndDate.getTime() - newDate.getTime()) / 36e5 -
                (timeLogSetting?.breakDuration || 0) / 60
              : (newEndDate.getTime() - newDate.getTime()) / 36e5,

            // set breakWasIncluded to true if includeBreak is true and type is not overtime
            breakWasIncluded: includeBreak && values.type !== 'overtime',
          };

          // Create log entry
          const newLogEntry = {
            id: crypto.randomUUID(),
            author: user.uid,
            action: 'create_entry',
            timestamp: Timestamp.now(),
            breakWasIncluded: includeBreak && values.type !== 'overtime',
            details: {
              ...newEntry,
            },
          };

          // See if we have an existing document or new document for the date currently being processed
          const timeLogDocRef = doc(
            db,
            'users',
            user.uid,
            'logs',
            newDate.toISOString().split('T')[0]
          );

          await setDoc(
            timeLogDocRef,
            {
              date: Timestamp.fromDate(newDate),
              entries: arrayUnion(newEntry),
              logs: arrayUnion(newLogEntry), // Add log entry
            },
            { merge: true }
          );
        }

        // Close modal after all entries are saved
        closeModal();
      } catch (error) {
        console.error('Error setting document:', error);

        // Set feedback if there is an error
        setFeedback('Der skete en fejl under oprettelsen af registreringen.');
      } finally {
        // Set submitting to false to enable the submit button
        setSubmitting(false);
        setSubmitLabel('Gem');
      }

      return;
    }

    // Since we are not creating multiple entries, we can just update the existing entry or create a new one if it does not exist
    else {
      // Date in format of YYYY-MM-DD
      const date = new Date(values.startTime.toDate());
      const utcDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );

      const isHoliday = DANISH_HOLIDAYS.some((holiday) => {
        const holidayDate = new Date(holiday.value);
        const holidayUtc = new Date(
          Date.UTC(
            holidayDate.getFullYear(),
            holidayDate.getMonth(),
            holidayDate.getDate()
          )
        );
        return holidayUtc.getTime() === utcDate.getTime();
      });

      // Find normal working hours from timeLogSettings, and use as default
      const timeLogSetting = currentTimeLogSetting(company, userInfo, utcDate);

      const timeLogDocRef = doc(
        db,
        'users',
        user.uid,
        'logs',
        timeLog?.id || utcDate.toISOString().split('T')[0]
      );

      const existingEntry = timeLog?.data?.entries.find(
        (entry) => entry.id === values.id
      );

      // If the user has a time log setting, use the default hours
      const fromDefaultTimeUTC = new Date(
        timeLogSetting?.from.toDate() || new Date()
      );
      const endDefaultTimeUTC = new Date(
        timeLogSetting?.end.toDate() || new Date()
      );

      // Convert start and end time to UTC
      const startTimeUTC = new Date(values.startTime.toDate().toISOString());
      const endTimeUTC = new Date(values.endTime.toDate().toISOString());

      // Get UTC hours
      const startTimeHour = startTimeUTC.getHours();
      const endTimeHour = endTimeUTC.getHours();
      const workStartHour = fromDefaultTimeUTC.getHours();
      const workEndHour = endDefaultTimeUTC.getHours();

      // If type is overtime, user can only register outside of normal working hours
      if (values.type === 'overtime') {
        if (
          !(startTimeHour >= workEndHour || endTimeHour <= workStartHour) &&
          // if the user is not working, they can register overtime at any time
          !timeLogSetting?.notWorking &&
          // If its a holiday, the user can register overtime at any time
          !isHoliday
        ) {
          setFeedback(
            'Arbejde uden for normtid kan kun registreres uden for norm tiden.'
          );

          setSubmitting(false);

          return;
        }
      }

      // If type is holiday, sickLeave or timeOff, user can only register within normal working hours
      if (
        (values.type === 'holiday' ||
          values.type === 'sickLeave' ||
          values.type === 'timeOff') &&
        !timeLogSetting?.isPaidHourly
      ) {
        if (
          startTimeHour < workStartHour ||
          endTimeHour > workEndHour ||
          // if the user is not working, they cannot register holiday, sickLeave or timeOff
          timeLogSetting?.notWorking
        ) {
          setFeedback(
            'Ferie, sygdom og fri kan kun registreres inden for norm tiden.'
          );

          setSubmitting(false);

          return;
        }
      }

      // Create log entry
      const newLogEntry = {
        id: crypto.randomUUID(),
        author: user.uid,
        action: existingEntry ? 'update_entry' : 'create_entry',
        timestamp: Timestamp.now(),
        details: {
          ...values,
          // Add previous hours if the entry already exists
          previousHours: existingEntry?.hours || 0,
          breakWasIncluded: includeBreak && values.type !== 'overtime',
        },
      };

      try {
        // Add or update the entry to Firestore
        await setDoc(
          timeLogDocRef,
          {
            date: timeLog?.id
              ? Timestamp.fromDate(new Date(timeLog.id))
              : Timestamp.fromDate(utcDate),
            entries: existingEntry
              ? timeLog?.data?.entries.map((entry) => {
                  if (entry.id === values.id) {
                    return {
                      ...entry,
                      ...values,
                      breakWasIncluded:
                        includeBreak && values.type !== 'overtime',
                    };
                  }
                  return entry;
                })
              : [
                  ...(timeLog?.data?.entries || []),
                  {
                    ...values,
                    id: crypto.randomUUID(),
                    breakWasIncluded:
                      includeBreak && values.type !== 'overtime',
                  },
                ],
            logs: arrayUnion(newLogEntry), // Add log entry
          },
          { merge: true }
        );

        // Check if this change should trigger a 11 hour warning or 48 hour warning, by looking at next day's timeLogSetting
        const nextDay = new Date(utcDate);
        nextDay.setDate(nextDay.getDate() + 1);

        const nextDayTimeLogSetting = currentTimeLogSetting(
          company,
          userInfo,
          nextDay
        );

        // Check if the next day is a working day
        if (nextDayTimeLogSetting?.notWorking) {
          closeModal();
          return;
        }

        // Check if the next day is a holiday
        const isHoliday = DANISH_HOLIDAYS.some((holiday) => {
          const holidayDate = new Date(holiday.value);
          const holidayUtc = new Date(
            Date.UTC(
              holidayDate.getFullYear(),
              holidayDate.getMonth(),
              holidayDate.getDate()
            )
          );
          return holidayUtc.getTime() === nextDay.getTime();
        });

        if (isHoliday) {
          closeModal();
          return;
        }

        // Check if this change should trigger a 11 hour warning
        const nextDayFrom = nextDayTimeLogSetting?.from.toDate();
        if (!nextDayFrom) {
          closeModal();
          return;
        }

        const actualNextDayFrom = new Date(
          nextDay.getFullYear(),
          nextDay.getMonth(),
          nextDay.getDate(),
          nextDayFrom.getHours(),
          nextDayFrom.getMinutes()
        );

        const hoursBetween = hoursBetweenTwoDates(
          values.endTime.toDate(),
          actualNextDayFrom
        );

        // There's less than 11 hours between the end of this entry and the start of the next day
        if (hoursBetween < 11) {
          const userDocRef = doc(db, 'users', user.uid);
          const existingWarnings = userInfo?.timeLogWarnings || [];

          // Helper function to extract the date portion (ignoring time)
          const getDateOnly = (timestamp: Timestamp) => {
            const date = timestamp.toDate();
            return new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
          };

          const newFromDate = getDateOnly(
            Timestamp.fromDate(values.endTime.toDate())
          );
          const newToDate = getDateOnly(Timestamp.fromDate(actualNextDayFrom));

          // Check if there's already an 11HourWarning for the same date (ignoring time)
          const existingWarningIndex = existingWarnings.findIndex(
            (warning: TimeLogWarning) =>
              warning.type === '11HourWarning' &&
              getDateOnly(warning.fromDate).getTime() ===
                newFromDate.getTime() &&
              getDateOnly(warning.toDate).getTime() === newToDate.getTime()
          );

          if (existingWarningIndex !== -1) {
            // Update existing warning
            existingWarnings[existingWarningIndex].value =
              Math.abs(hoursBetween);
          } else {
            // Add a new warning
            existingWarnings.push({
              id: crypto.randomUUID(),
              type: '11HourWarning',
              fromDate: Timestamp.fromDate(values.endTime.toDate()),
              toDate: Timestamp.fromDate(actualNextDayFrom),
              value: Math.abs(hoursBetween),
            } as TimeLogWarning);
          }

          try {
            // Update the user's document with the new or updated warnings
            await setDoc(
              userDocRef,
              { timeLogWarnings: existingWarnings },
              { merge: true }
            );

            // Close modal after saving
            closeModal();

            // TODO: 48 hour warning
          } catch (error) {
            console.error('Error setting 11 hour warning:', error);
          }
        } else {
          // If no warning is triggered, close the modal
          closeModal();
        }
      } catch (error) {
        console.error('Error setting document:', error);

        // Set feedback if there is an error
        setFeedback('Der skete en fejl under oprettelsen af registreringen.');
      }
    }
  }, [
    values,
    user,
    company,
    timeLog,
    closeModal,
    userInfo,
    showFromToDates,
    includeBreak,
    // Add other dependencies if necessary
  ]);

  const handleDelete = useCallback(async () => {
    if (!values.id) return;

    const timeLogDocRef = doc(
      db,
      'users',
      user.uid,
      'logs',
      new Date(values.startTime.toDate()).toISOString().split('T')[0]
    );

    // Create log entry
    const newLogEntry = {
      id: crypto.randomUUID(),
      author: user.uid,
      action: 'delete_entry',
      timestamp: Timestamp.now(),
      details: {
        ...values,
      },
    };

    try {
      await setDoc(
        timeLogDocRef,
        {
          date: Timestamp.fromDate(new Date(values.startTime.toDate())),
          entries: timeLog?.data?.entries.filter(
            (entry) => entry.id !== values.id
          ),
          logs: arrayUnion(newLogEntry), // Add log entry
        },
        { merge: true }
      );

      closeModal();
    } catch (error) {
      console.error('Error setting document:', error);
    }
  }, [values, user, timeLog, closeModal]);

  const handleDateChange = (direction: number) => () => {
    const date = new Date(values.startTime.toDate());
    date.setDate(date.getDate() + direction);

    setValues({
      ...values,
      startTime: Timestamp.fromDate(date),
      endTime: Timestamp.fromDate(date),
    });
  };

  const handleIncludeBreakChange = (value: boolean) => {
    setIncludeBreak(value);

    // Update calculated hours
    const timeLogSetting = currentTimeLogSetting(
      company,
      userInfo,
      new Date(values.startTime.toDate())
    );

    const start = values.startTime.toMillis();
    const end = values.endTime.toMillis();
    const calculatedHours = value
      ? (end - start) / 36e5 - (timeLogSetting?.breakDuration || 0) / 60
      : (end - start) / 36e5;

    setValues({
      ...values,
      hours: calculatedHours,
      breakWasIncluded: value,
    });
  };

  // useEffect to lock the scroll when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // useEffect to update the values when the preValues change, e.g. when the modal is opened
  useEffect(() => {
    setValues(preValues);

    if (preValues.breakWasIncluded) {
      setIncludeBreak(true);
    } else {
      setIncludeBreak(false);
    }
    // If preValues has a timeoffType, show the timeOffType field
    if (preValues.timeOffType || preValues.type === 'timeOff') {
      setShowTimeOffType(true);
    }
    if (preValues.type === 'holiday' || preValues.type === 'timeOff') {
      setShowDateRangeToggle(true);
    } else {
      setShowDateRangeToggle(false);
      setShowFromToDates(false);
    }
  }, [preValues]);

  return (
    <>
      <motion.div
        className={`${styles.ModalBackground}`}
        initial={{ opacity: 0 }}
        animate={{
          opacity: isOpen ? 1 : 0,
        }}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
        onClick={closeModal}
        transition={{ duration: 0.29, easings: 'easeInOut' }}
      />
      <motion.div
        className={styles.Modal}
        initial={{ transform: 'translateY(101%)' }}
        animate={{
          transform: isOpen ? 'translateY(0)' : 'translateY(101%)',
        }}
        transition={{ duration: 0.29, easings: 'easeInOut' }}
      >
        <div className={styles.ModalHeader}>
          <h5>Registrering</h5>
          <button className={styles.ModalHeaderClose} onClick={closeModal}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.295 7.115C18.6844 6.72564 18.6844 6.09436 18.295 5.705C17.9056 5.31564 17.2744 5.31564 16.885 5.705L12 10.59L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L10.59 12L5.705 16.885C5.31564 17.2744 5.31564 17.9056 5.705 18.295C6.09436 18.6844 6.72564 18.6844 7.115 18.295L12 13.41L16.885 18.295C17.2744 18.6844 17.9056 18.6844 18.295 18.295C18.6844 17.9056 18.6844 17.2744 18.295 16.885L13.41 12L18.295 7.115Z"
                fill="#194A37"
              />
            </svg>
          </button>
        </div>

        <div className={styles.ModalContent}>
          <div className={styles.ModalDateSelector}>
            {!showFromToDates ? (
              <>
                <button
                  className={styles.ModalDateSelectorPreviousButton}
                  disabled={(() => {
                    // Check if the previous date is locked, if so, disable the button
                    const previousDate = new Date(values.startTime.toDate());
                    previousDate.setDate(previousDate.getDate() - 1);

                    return isDateLocked(
                      previousDate,
                      userInfo.timeLogApprovedWeeks,
                      userInfo.startDate,
                      getCompanyStartDate(company)
                    );

                    // If the date is locked, return true, else return false
                  })()}
                  onClick={handleDateChange(-1)}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 25L16.7071 20.7071C16.3166 20.3166 16.3166 19.6834 16.7071 19.2929L21 15"
                      stroke="#194a37"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div className={styles.ModalDateSelectorCurrent}>
                  <h3 className={styles.ModalDateSelectorCurrentDate}>
                    {new Date(values.startTime.toDate()).toLocaleDateString(
                      'da-DK',
                      {
                        day: 'numeric',
                        month: 'long',
                      }
                    )}
                  </h3>

                  <span className={styles.ModalDateSelectorCurrentDateWeekDay}>
                    {new Date(values.startTime.toDate()).toLocaleDateString(
                      'da-DK',
                      {
                        weekday: 'long',
                      }
                    )}
                  </span>
                </div>
                <button
                  className={styles.ModalDateSelectorNextButton}
                  onClick={handleDateChange(1)}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 25L23.2929 20.7071C23.6834 20.3166 23.6834 19.6834 23.2929 19.2929L19 15"
                      stroke="#194a37"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
              </>
            ) : (
              <div className={styles.ModalDateSelectorCurrent}>
                <h3 className={styles.ModalDateSelectorCurrentDate}>
                  {
                    // When we have a start and end date, we show the range
                    `${new Date(values.startTime.toDate()).toLocaleDateString(
                      'da-DK',
                      {
                        day: 'numeric',
                        month: 'long',
                      }
                    )} - ${new Date(values.endTime.toDate()).toLocaleDateString(
                      'da-DK',
                      {
                        day: 'numeric',
                        month: 'long',
                      }
                    )}`
                  }
                </h3>
              </div>
            )}
          </div>
          <div className={styles.ModalFields}>
            <InputField
              name="type"
              label="Type"
              type="select"
              placeholder="- Vælg -"
              value={values.type}
              onChange={handleTypeChange}
              options={
                // If timeLogSetting.isPaidHourly is true, filter out overtime
                // or, if timeLogSetting.isPaidhourly is false, filter out work
                TIME_LOG_ENTRY_TYPES.filter(
                  (type) =>
                    !(
                      type.value === 'overtime' &&
                      currentTimeLogSetting(
                        company,
                        userInfo,
                        new Date(values.startTime.toDate())
                      )?.isPaidHourly
                    ) && // Filter out overtime if user is paid hourly
                    !(
                      type.value === 'work' &&
                      !currentTimeLogSetting(
                        company,
                        userInfo,
                        new Date(values.startTime.toDate())
                      )?.isPaidHourly
                    ) // Filter out work if user is not paid hourly
                )
              }
              disabled={!!values.id}
              visibleLabel
            />
            {showTimeOffType && (
              <InputField
                name="timeOffType"
                label="Type af fri"
                type="select"
                placeholder="- Vælg -"
                value={values.timeOffType}
                onChange={handleChange}
                options={company.timeOffTypes}
                disabled={!!values.id}
                visibleLabel
              />
            )}
            {showDateRangeToggle && (
              <InputField
                name="showDateRangeToggle"
                type="switch"
                label="Vælg flere dage"
                onChange={() => setShowFromToDates(!showFromToDates)}
              />
            )}
            {!currentTimeLogSetting(
              company,
              userInfo,
              new Date(values.startTime.toDate())
            )?.isPaidHourly &&
              showIncludeBreakToggle && (
                <InputField
                  name="showIncludeBreakToggle"
                  type="switch"
                  label="Medregn pause"
                  checked={includeBreak}
                  onChange={() => handleIncludeBreakChange(!includeBreak)}
                />
              )}
            {showFromToDates ? (
              <>
                <div className={styles.ModalFieldsRow}>
                  <InputField
                    name="startDate"
                    label="Fra"
                    type="date"
                    value={(() => {
                      const date = new Date(values.startTime.toDate());
                      return isNaN(date.getTime())
                        ? ''
                        : date.toISOString().split('T')[0];
                    })()}
                    defaultValue={(() => {
                      const date = new Date(values.startTime.toDate());
                      return isNaN(date.getTime())
                        ? ''
                        : date.toISOString().split('T')[0];
                    })()}
                    onChange={handleChange}
                    visibleLabel
                    className={styles.ModalFieldsDateInput}
                  />
                  <InputField
                    name="endDate"
                    label="Til"
                    type="date"
                    value={(() => {
                      const date = new Date(values.endTime.toDate());
                      return isNaN(date.getTime())
                        ? ''
                        : date.toISOString().split('T')[0];
                    })()}
                    defaultValue={(() => {
                      const date = new Date(values.endTime.toDate());
                      return isNaN(date.getTime())
                        ? ''
                        : date.toISOString().split('T')[0];
                    })()}
                    onChange={handleChange}
                    visibleLabel
                    className={styles.ModalFieldsDateInput}
                  />
                </div>
                <InputField
                  name="days"
                  label="Dage"
                  type="number"
                  key={`days-${daysCalculated}`}
                  value={daysCalculated}
                  disabled
                  visibleLabel
                  className={styles.ModalFieldsDaysInput}
                />
                {feedback && (
                  <div className={styles.ModalFieldsFeedback}>{feedback}</div>
                )}
              </>
            ) : (
              <>
                <div className={styles.ModalFieldsRow}>
                  <InputField
                    name="startTime"
                    label="Fra"
                    type="time"
                    // Convert the firebase timestamp to hours:minutes string
                    value={
                      new Date(values.startTime.toDate())
                        .toLocaleTimeString('da-DK', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                        .replace('.', ':') || ''
                    }
                    defaultValue={
                      new Date(values.startTime.toDate())
                        .toLocaleTimeString('da-DK', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                        .replace('.', ':') || ''
                    }
                    onChange={handleChange}
                    visibleLabel
                    className={styles.ModalFieldsTimeInput}
                  />
                  <InputField
                    name="endTime"
                    label="Til"
                    type="time"
                    value={new Date(values.endTime.toDate())
                      .toLocaleTimeString('da-DK', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace('.', ':')}
                    defaultValue={new Date(values.endTime.toDate())
                      .toLocaleTimeString('da-DK', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace('.', ':')}
                    onChange={handleChange}
                    visibleLabel
                    className={styles.ModalFieldsTimeInput}
                  />
                  <InputField
                    name="hours"
                    label="Timer"
                    type="number"
                    key={`hours-${values.hours}`}
                    value={values.hours?.toFixed(2) || ''}
                    disabled
                    visibleLabel
                    className={styles.ModalFieldsHoursInput}
                  />
                </div>
                <div className={styles.ModalFieldsHelpText}>
                  Tid rundes til nærmeste kvarter.
                </div>
                {feedback && (
                  <div className={styles.ModalFieldsFeedback}>{feedback}</div>
                )}
              </>
            )}
            <InputField
              name="note"
              label="Kommentar"
              type="textarea"
              value={values.note}
              placeholder="Tilføj en kommentar til registreringen"
              onChange={handleChange}
              visibleLabel
            />
          </div>
          {values.id && (
            <div className={styles.ModalDeleteButtonWrapper}>
              <Button onClick={handleDelete} wide buttonStyle="text-link">
                Slet registrering
              </Button>
            </div>
          )}
        </div>

        <div className={styles.ModalFooter}>
          <Button
            wide
            onClick={handleSubmit}
            disabled={(!values.type && !values.hours) || submitting}
          >
            {submitLabel}
          </Button>
        </div>
      </motion.div>
    </>
  );
}

export default TimeModal;
